import Image from 'next/image';

type PopoverMenuIconProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
};

function PopoverMenuItem({ icon, ...props }: PopoverMenuIconProps) {
  return (
    <span className="flex w-full items-center space-x-1">
      <div className="flex w-4 justify-center">{icon}</div>
      <span>{props.children}</span>
    </span>
  );
}

export default PopoverMenuItem;
