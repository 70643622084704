import clsx from 'clsx';
import { useState } from 'react';

import Modal, { ModalState } from '../Modal';
import MobileNavLink from './MobileNavLink';
import NavGroup from './NavGroup';
import MenuIcon from './menu-icon.svg';

export { NavGroup, MobileNavLink };

type MobileNavProps = {
  triggerColor?: 'white' | 'black';
  children: ((modalState: ModalState) => React.ReactNode) | React.ReactNode;
};

function MobileNav({ triggerColor = 'white', children }: MobileNavProps) {
  return (
    <Modal
      label="Menu"
      trigger={
        <div
          className={clsx('flex h-7 w-7 items-center justify-center', {
            'text-white': triggerColor === 'white',
            'text-black': triggerColor === 'black',
          })}
        >
          <MenuIcon className="fill-current h-5 w-5" />
        </div>
      }
      backdropClassName="backdrop bg-black bg-opacity-60"
      modalClassName="modal-dialog-from-left h-full w-full mr-auto"
      options={{hideOnClickOutside: true}}
    >
      {modalState => (
        <div className="flex h-full w-[70%] max-w-[215px] flex-col justify-between overflow-y-auto bg-neutral-900 px-2 py-4 text-white">
          {typeof children === 'function' ? children(modalState) : children}
        </div>
      )}
    </Modal>
  );
}

export default MobileNav;
