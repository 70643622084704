import clsx from 'clsx';

import Icon from './spinner-icon.svg';

type Props = {
  scale?: '1' | '2';
  color?: 'navy' | 'red';
};

function Spinner({ scale = '2', color = 'navy' }: Props = { scale: '2', color: 'navy' }) {
  return (
    <div className="flex w-full justify-center">
      <div
        className={clsx({
          ['scale-[1]']: scale === '1',
          ['scale-[2]']: scale === '2',
          ['text-red-50']: color === 'red',
          ['text-gray-900']: color === 'navy',
        })}
      >
        <Icon className="origin-center animate-spin fill-current duration-100" />
      </div>
    </div>
  );
}

export default Spinner;
