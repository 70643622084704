import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ModalState } from '../Modal';

type MobileNavLinkProps = {
  href: string;
  label: string;
  icon: React.ReactNode;
  exact?: boolean;
  modalState: ModalState;
};

export function MobileNavLink(props: MobileNavLinkProps) {
  const router = useRouter();

  const isActive = props.exact
    ? router.asPath === props.href
    : router.asPath.startsWith(props.href);

  return (
    <Link href={props.href}>
      <a
        className={clsx('flex items-center px-2.5', {
          'text-white': isActive,
          'text-neutral-600 hover:text-neutral-200': !isActive,
        })}
        onClick={() => props.modalState.hide()}
      >
        <span className="inline-flex w-[40px] items-center justify-center">{props.icon}</span>
        <span className="pl-3 text-sm">{props.label}</span>
      </a>
    </Link>
  );
}

export default MobileNavLink;
