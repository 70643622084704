import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { PopoverStateReturn } from 'reakit/ts';

import BasePopover, { BasePopoverProps } from './BasePopover';

export type MenuItem = {
  type?: 'link' | 'external' | 'button';
  onClick?: () => void;
  disabled?: boolean;
  closeOnClick?: boolean;
  label: React.ReactNode;
  href?: string;
};

type PopoverMenuProps = {
  trigger: React.ReactNode;
  label: string;
  options?: BasePopoverProps['options'];
  menu: MenuItem[];
  closeOnClick?: boolean;
};

/**
 * A popover that display routes. Depends on next-router.
 * Our popover system is created on top of [reakit/popover](https://reakit.io/docs/popover/),
 * an unstyled and a11y compliant base component library.
 */
function PopoverMenu({ menu, label, trigger, options, closeOnClick = false }: PopoverMenuProps) {
  const renderItem = (menuItem: MenuItem, popover: PopoverStateReturn) => {
    const { type = 'link' } = menuItem;

    if (type === 'link') {
      return (
        <Link href={menuItem.href as string}>
          <a
            onClick={() => {
              popover.hide();
              menuItem.onClick?.();
            }}
            className="block whitespace-nowrap text-black hover:font-normal"
          >
            {menuItem.label}
          </a>
        </Link>
      );
    }

    if (type === 'external') {
      return (
        <a
          onClick={() => {
            popover.hide();
            menuItem.onClick?.();
          }}
          href={menuItem.href}
          rel="noopener noreferrer"
          className="block whitespace-nowrap text-black hover:font-normal"
        >
          {menuItem.label}
        </a>
      );
    }

    return (
      <button
        disabled={menuItem.disabled}
        type="button"
        onClick={() => {
          if (closeOnClick || menuItem.closeOnClick) {
            popover.hide();
          }
          menuItem.onClick?.();
        }}
        className="block w-full whitespace-nowrap text-left text-black hover:font-normal"
      >
        {menuItem.label}
      </button>
    );
  };

  return (
    <BasePopover label={label} options={options} trigger={trigger}>
      {popover => (
        <ul className="py-2">
          {menu.map((menuItem, i) => (
            <div className="px-2" key={i}>
              <li
                className={clsx('py-2 text-sm', {
                  'border-b border-gray-200': i < menu.length - 1,
                })}
              >
                {renderItem(menuItem, popover)}
              </li>
            </div>
          ))}
        </ul>
      )}
    </BasePopover>
  );
}

export default PopoverMenu;
