import { NextPage } from 'next';
import { NextRouter } from 'next/router';
import React, { ReactElement, ReactNode } from 'react';

import { UseCurrentUserResponse } from 'store/auth/useCurrentUser';

import ErrorMessage from './ErrorMessage';
import SigninForm from './SigninForm';
import Spinner from './Spinner';
import getAuthLayout from './getAuthLayout';

type TPage = NextPage & {
  getLayout?: (page: ReactElement, router?: NextRouter) => ReactNode;
  getInitialProps?: Function;
};

function requireAuth(Page: TPage) {
  const LoginRedirector = (props: any) => {
    return <Page {...props} />;
  };

  LoginRedirector.getInitialProps = Page.getInitialProps;
  LoginRedirector.getLayout = (
    page: ReactElement,
    router: NextRouter,
    userQuery: UseCurrentUserResponse,
  ) => {
    const [{ data, error, fetching }, refetch] = userQuery;

    if (fetching && !error) {
      return getAuthLayout((<Spinner />) as any, router);
    }

    if (error) {
      return getAuthLayout(
        (
          <ErrorMessage
            error={error}
            loading={fetching}
            retry={() => refetch({ requestPolicy: 'network-only' })}
          />
        ) as any,
        router,
      );
    }

    if (!data?.me) {
      return getAuthLayout((<SigninForm />) as any, router);
    }

    return Page.getLayout?.(page, router) ?? page;
  };

  return LoginRedirector;
}

export default requireAuth;
