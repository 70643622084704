import { CombinedError } from 'urql';

import Button from 'lib/components/Button';

import Header from './Header';

type Props = {
  title?: string;
  message?: string;
  retry?: Function;
  error?: CombinedError | undefined;
  loading?: boolean;
  retryLabel?: string;
};

function ErrorMessage({
  title = 'Something went wrong',
  message = 'Please check your internet connection and try again',
  retryLabel = 'Refresh',
  error,
  ...props
}: Props) {
  return (
    <div className="flex  w-full flex-col items-center justify-center space-y-4">
      <div className="space-y-1 text-center">
        <Header className='!text-primary-500 !text-xl !font-medium'>{title}</Header>
        <p>{message}</p>
      </div>
      {props.retry && (
        <Button loading={Boolean(props.loading)} onClick={() => props.retry?.()}>
          {retryLabel}
        </Button>
      )}
    </div>
  );
}

export default ErrorMessage;
