import { useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
  DialogOptions,
  DialogStateReturn,
  useDialogState,
} from 'reakit/Dialog';

export type ModalState<TData = any> = Omit<DialogStateReturn, 'show'> & {
  modalData?: TData;
  setData: (data: TData) => void;
  show: (data?: TData) => void;
};

export function useModalState<TData = any>(options?: Partial<DialogOptions>): ModalState<TData> {
  const modal = useDialogState({ ...options, animated: 250 });
  const [modalData, setData] = useState<TData>();

  return {
    ...modal,
    modalData,
    setData,
    show(data) {
      setData(data);
      modal.show();
    },
    hide() {
      modal.hide();
    },
  };
}

type ModalProps<TData> = {
  /**
   * You can specify both JSX or a function that returns JSX as the Modal children.
   * When passing a function, it'll receive a state object as argument.
   */
  children: React.ReactNode | ((modalState: ModalState<TData>) => JSX.Element);
  /**
   * the content for the button that'll trigger the modal
   */
  trigger?: React.ReactNode;
  /**
   * This is required to make the modal accessible to screen readers and such
   */
  label: string;
  /**
   * An object containing the current modal display status and extra data.
   * Use it to control the modal externally.
   * Don't make a state object from scratch. Use the useModalState hook.
   */
  modalState?: ModalState<TData>;
  /**
   * The modal content custom className. if provided, there will be no default styles
   */
  modalClassName?: string;
  /**
   * The modal backdrop custom className. if provided, there will be no default styles
   */
  backdropClassName?: string;
  options?: { hideOnEsc?: boolean; hideOnClickOutside?: boolean; preventBodyScroll?: boolean };
};

/**
 * A modal dialog controlled by the modalState hook.
 * Our modal system is created on top of [reakit/dialog](https://reakit.io/docs/dialog/),
 * an unstyled and a11y compliant base component library.
 */
function Modal<TData>({
  children,
  label,
  trigger,
  modalState,
  options = { preventBodyScroll: false },
  ...props
}: ModalProps<TData>) {
  const fallbackState = useModalState(options);

  const { modalData, setData, ...state } = modalState ?? fallbackState;

  return (
    <>
      {trigger && (
        <DialogDisclosure {...state} className="block">
          {trigger}
        </DialogDisclosure>
      )}
      <DialogBackdrop
        {...state}
        className={
          props.backdropClassName ??
          'backdrop overflow-y-auto overflow-x-hidden bg-black bg-opacity-60 px-4 py-4 lg:py-24'
        }
      >
        <Dialog
          {...state}
          {...options}
          tabIndex={0}
          aria-label={label}
          className={
            props.modalClassName ??
            'modal-dialog w-full min-w-[300px] max-w-[600px] rounded-base bg-gray-50'
          }
        >
          {typeof children === 'function' ? children({ modalData, setData, ...state }) : children}
        </Dialog>
      </DialogBackdrop>
    </>
  );
}

export default Modal;
