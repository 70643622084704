import clsx from 'clsx';
import React from 'react';
import { Popover, PopoverDisclosure, PopoverStateReturn, usePopoverState } from 'reakit/Popover';

export type BasePopoverProps = {
  /**
   * The popover content
   */
  children: React.ReactNode | ((popover: PopoverStateReturn) => React.ReactNode);
  trigger?: React.ReactNode;
  label: string;
  className?: string;
  classNameTrigger?: string;
  disclosure?: any;
  options?: {
    gutter?: number;
    className?: string;
    tabIndex?: number;
    unstable_offset?: [number, number];
    placement?:
      | 'auto-start'
      | 'auto'
      | 'auto-end'
      | 'top-start'
      | 'top'
      | 'top-end'
      | 'right-start'
      | 'right'
      | 'right-end'
      | 'bottom-end'
      | 'bottom'
      | 'bottom-start'
      | 'left-end'
      | 'left'
      | 'left-start';
  };
  variant?: string;
};

/**
 * A simple popover.
 * Our popover system is created on top of [reakit/popover](https://reakit.io/docs/popover/),
 * an unstyled and a11y compliant base component library.
 */
function BasePopover({
  children,
  label,
  trigger,
  className,
  classNameTrigger = 'w-full outline-none',
  disclosure,
  variant,
  options = {},
}: BasePopoverProps) {
  const popover = usePopoverState({ ...options, animated: 250 });
  const { tabIndex } = options;

  return (
    <>
      {disclosure ? (
        <PopoverDisclosure {...popover} ref={disclosure.ref} {...disclosure.props}>
          {disclosureProps => React.cloneElement(disclosure, disclosureProps)}
        </PopoverDisclosure>
      ) : (
        <PopoverDisclosure {...popover} type="button" className={classNameTrigger}>
          {trigger}
        </PopoverDisclosure>
      )}
      <Popover {...popover} aria-label={label} tabIndex={tabIndex} className="z-[99] outline-none">
        <div
          className={clsx(
            'animate-from-top relative max-h-80 min-w-[100px] overflow-auto rounded-base bg-white shadow-lg lg:mr-3',
            className, {
              'border-gray-900 border': variant === 'dark', 
              'border-gray-200 border': variant !== 'dark',
            })
          }
        >
          {typeof children === 'function' ? children(popover) : children}
        </div>
      </Popover>
    </>
  );
}

export default BasePopover;
