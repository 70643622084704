import { validate } from 'email-validator';
import Cookies from 'js-cookie';
import { identity } from 'lodash';
import { OperationResult, gql, useMutation } from 'urql';

import client from 'store';
import {
  ObtainJsonWebToken,
  RootMutationLoginArgs,
  RootMutationStoreCreateUsingEmailArgs,
} from 'store/types';

import { GET_USER } from './useCurrentUser';

const LOGIN = gql`
  mutation login($password: String!, $username: String, $email: String) {
    login(password: $password, username: $username, email: $email) {
      success
      errors
      token
    }
  }
`;

async function login(variables: { identity: string; password: string }) {
  const loginVariables: RootMutationLoginArgs = { password: variables.password };
  const isEmail = validate(variables.identity);

  if (isEmail) {
    loginVariables.email = variables.identity;
  } else {
    loginVariables.username = variables.identity;
  }

  const { data, error }: OperationResult<{ login: ObtainJsonWebToken }> = await client
    .mutation(LOGIN, loginVariables)
    .toPromise();

  if (error) {
    throw error;
  }

  if (data?.login.token) {
    Cookies.set('token', data.login.token);
    await client.query(GET_USER, undefined, { requestPolicy: 'network-only' }).toPromise();
  }

  return data?.login as ObtainJsonWebToken;
}

export default login;
